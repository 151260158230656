<template>
    <div class="main">
        <Title class="title">{{data.title}}</Title>
        <p class="time">{{data.time}}</p>
        <div v-for="(item,index) in data.content" :key="index">
          <div v-if="item[0]=='img'" class="img-box">
              <ImagePreview class="img" :src="require('../../assets/News/Ls/'+item[1])" :srcList="[require('../../assets/News/Ls/'+item[1])]"></ImagePreview>
          </div>
          <Pgp v-else>{{item[1]}}</Pgp>
        </div>
        <!-- <div class="img-box">
            <ImagePreview v-for="img in data.img" :key="img" class="img" :src="require('../../assets/News/News'+img)" :srcList="[require('../../assets/News/News'+img)]"></ImagePreview>
        </div>
        <Pgp>{{data.content}}</Pgp> -->
        <!-- <ImagePreview v-for="img in data.moreImg" :key="img" class="more_img" :src="require('../../assets/News/News'+img)" :srcList="[require('../../assets/News/News'+img)]"></ImagePreview> -->
        <div class="line"></div>
        <div class="footer">
            <div class="left">
                <div class="item"><p class="nowrap">上一篇：</p><router-link class="a" v-if="preData.title" :to="'/news/ls_detail/'+preData.id">{{preData.title}}</router-link><span v-else>无</span></div>
                <div class="item"><p class="nowrap">下一篇：</p><router-link class="a" v-if="nextData.title" :to="'/news/ls_detail/'+nextData.id">{{nextData.title}}</router-link><span v-else>无</span></div>
            </div>
            <div class="right">
                <el-button type="primary" @click="goBack">返回列表</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        console.log(this.$route)
        // switch(this.id){
        //     case '1':

        //     break;
        // }

    },
    computed:{
        id(){
            return this.$route.params.id
        },
        data(){
            return this.$store.state.lsData.filter(item=>item.id == this.$route.params.id)[0]
        },
        preData(){//上一篇
            let id = this.$route.params.id-1;
            return this.$store.state.lsData.filter(item=>item.id == id)[0] || []
        },
        nextData(){//下一篇
            let id = this.$route.params.id-0+1;
            return this.$store.state.lsData.filter(item=>item.id == id)[0] || []
        },
    },
    methods:{
        goBack(){
            this.$store.commit('set_path',{path:'/news/ls',query:{}})
        }
    }
}
</script>
<style scoped>
.title{
    color:#000;
    font-weight:bold;
}
.main{
    width:80vw;
    margin:0 auto;
    margin-bottom:2rem;
    max-width:1600px;
}
.img-box{
    margin:2rem 0;
    display: flex;
    justify-content: space-evenly;
}
.img{
    width:50%;
    padding:10px;
    max-width:500px;
}
.more_img{
    width:70%;
    padding:10px;
    max-width:800px;
    margin:0 auto;
}
.time{
    color:#b5b5b5;
    font-size:1.3rem;
    font-weight:bold;
}
.line{
    height:1px;
    width:100%;
    background: #284c91;
    margin: 1rem 0;
}
.footer{
    display:flex;
    justify-content:space-between;
}
.left{
    width:80%;
    display:flex;
    text-align:left;
}
.item{
    width:50%;
    display: flex;
}
/deep/ .el-button--primary{
    background-color: #284c91;
    border-color: #284c91;
}
.nowrap{
    white-space:nowrap;
}
.a{
    color: #284c91;
}
</style>